@import 'bp';
@import 'mixins';
@import 'mixins/modules';
@import 'spacing';
@import 'palette';

$slide-image-height: 180px;

$slide-width-desktop: 33.333%; // 33.333% for full
$slide-width-mobile: 75%; // 100% for full
$slide-spacer-mobile: 6.125%; // keeps first / last slide in "cnetered" position for slide peeking
$slide-gap-desktop: 20px;

.standard-carousel {
    @include standard-module;

    h2 + div,
    strong + div {
        text-align: center;
    }

    section h3 {
        margin-bottom: $spacing-00;
    }

    .standard-carousel-btn {
        display: inline-flex;

        a {
            max-width: $cta-min-width-desktop;
            text-transform: uppercase;
        }
    }
}

:global(.gtamerica) {
    &.standard-carousel {
        @include standard-module-gtamerica;
    }
}

.standard-carousel-nav {
    @include webpage-content-nav;
}

// @TODO Abstract common styles once Tile module is added
.standard-carousel-scrollable {
    display: flex;
    flex-direction: row;
    margin: $spacing-2 -#{$spacing-1};
    padding: 0;
    position: relative;
    scroll-snap-type: x mandatory;
    width: 100vw;

    &:before,
    &:after {
        content: ' ';
        display: flex;
        padding: $slide-spacer-mobile;
    }

    article {
        box-sizing: border-box;
        display: flex;
        flex: 1 0 $slide-width-mobile;
        flex-direction: column;
        margin: 0 0;
        padding: 0 $spacing-2;
        scroll-snap-align: center;
        text-align: center;
        white-space: initial;
        width: $slide-width-mobile;

        h3 small {
            display: block;
        }
    }
    &.carousel-items-pair article {
        flex: 1 0 $slide-width-mobile;
    }

    img {
        border-radius: $spacing--1;
        display: block;
        height: 100%;
        margin: 0 0 $spacing-2;
        max-height: $slide-image-height;
        max-width: 100%;
    }
}

.scrollable-item-image {
    margin-bottom: $spacing-2;
}

.scrollable-item-with-cta {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.scrollable-controls {
    margin-bottom: $spacing-4;
}

@media screen and (min-width: 640px) {
    .standard-carousel.four-up {
        .standard-carousel-scrollable article {
            flex: 1 0 25%;
            width: 25%;
        }
    }

    .standard-carousel-scrollable {
        margin: $spacing-2 0;
        width: 100%;

        &:before,
        &:after {
            content: none;
        }

        article {
            flex: 1 0 $slide-width-desktop;
            margin: 0;
            padding: 0 $slide-gap-desktop;
            scroll-snap-align: start;
            width: $slide-width-desktop;

            &.four-up {
                flex: 1 0 25%;
                width: 25%;
            }
        }

        img {
            height: $slide-image-height;
            max-height: 100%;
            width: 100%;
        }

        &.carousel-items-pair {
            justify-content: center;

            article {
                flex: unset;
            }
        }
    }
}
