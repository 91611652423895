@import 'bp';
@import 'mixins/modules';
@import 'spacing';

$cta-group-margin-top: 56px;

.standard-video {
    @include standard-module;
}

.standard-video-container {
    aspect-ratio: 16 / 9;
    border: none;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.standard-video-cta-group {
    @include webpage-content-nav;
    margin-top: $cta-group-margin-top;

    // Added same margin-top in media query to override webpage-content-nav styling
    @media screen and (min-width: $bp-sm) {
        margin-top: $cta-group-margin-top;
    }

    &.nav-graphic {
        flex-basis: unset;
        a {
            flex-basis: unset;
        }
    }
}

.standard-video-footer {
    text-align: center;

    @media screen and (min-width: $bp-sm) {
        max-width: 85%;
        margin: 0 auto;
    }
}

.standard-video-yt-container {
    width: 100%;
    height: 100%;
}
